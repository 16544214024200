import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarbonAdComponent } from './carbon-ad/carbon-ad.component';



@NgModule({
  declarations: [
    CarbonAdComponent
  ],
  imports: [
    CommonModule
  ], 
  exports: [
    CarbonAdComponent
  ]
})
export class SharedModule { }

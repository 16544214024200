import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';

declare var Chargebee: any;

@Component({
  selector: 'app-logged-in',
  templateUrl: './logged-in.component.html',
  styleUrls: ['./logged-in.component.css']
})
export class LoggedInComponent implements OnInit {
  user: firebase.User;

  staff: boolean;

  constructor(private service: AuthService, private db: AngularFirestore, private func: AngularFireFunctions, private http: HttpClient) { }

  ngOnInit() {
    this.service.getLoggedInUser().subscribe(user => {
      this.user = user;

      if (user) {

        this.db.collection("users").doc(this.user.uid).ref.get()
          .then(doc => {
            if (doc.exists) {
              if (doc.data().permission >= 1) {
                this.staff = true;
              }
            }
          })

        this.service.refreshToken();
      }
    })
  }

  logout() {
    this.service.logout();

    this.user = null;
  }

  getBilling() {
    this.service.returnUserToken().then(token => {
      this.http.get<any>(`https://api.campfirehq.net/user/billing-portal`, {
        headers: {
          authorization: token
        }
      }).pipe(first()).subscribe(callback => {
        if (callback.error) {
          Swal.fire('Error', callback.error, 'error');
        } else {
          const win = window.open(callback.url, '_blank');
          win.focus();
        }
      })
    })
  }
}

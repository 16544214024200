<div class="hero is-small is-light">
    <div class="hero-body">
        <div class="container">
            <h1 class="title is-size-2">{{greeting}}, {{ user?.displayName}}!</h1>
            <h3 class="subtitle is-size-3">Welcome to the Campfire Dashboard</h3>
        </div>
    </div>
</div>

<div class="spacer"></div>

<div class="container">
    <div class="columns">
        <div class="column has-text-centered">
            <h1 class="icon-large has-text-primary"><span class="icon is-large"><i class="fas fa-ticket-alt"></i></span></h1>
            <h2 class="title is-size-3">1000</h2>
            <h3 class="subtitle is-size-4">Tickets</h3>
        </div>

        <div class="column has-text-centered">
            <h1 class="icon-large has-text-primary"><span class="icon is-large"><i class="fas fa-piggy-bank"></i></span></h1>
            <h2 class="title is-size-3">1000</h2>
            <h3 class="subtitle is-size-4">Deposit</h3>
        </div>

        <div class="column has-text-centered">
            <h1 class="icon-large has-text-primary"><span class="icon is-large"><i class="fas fa-trophy"></i></span></h1>
            <h2 class="title is-size-3">1000</h2>
            <h3 class="subtitle is-size-4">Level</h3>
        </div>
    </div>
</div>

<div class="spacer"></div>

<div class="container">
    <p class="has-text-weight-bold">Your products</p>
    <br>
    <div class="columns">
        <div class="column is-2">
            <div class="box has-text-centered">
                <h1 class="title is-size-1 has-text-primary"><span class="icon"><i class="fas fa-robot"></i></span></h1>
                <p>Bot</p>
            </div>
        </div>

        <div class="column is-2">
            <div class="box has-text-centered">
                <h1 class="title is-size-1 has-text-primary"><span class="icon"><i class="fas fa-project-diagram"></i></span></h1>
                <p>Ranking</p>
            </div>
        </div>

    </div>
</div>

<div class="spacer"></div>

<div class="container">
    <div class="columns">
        <div class="column">
            <div class="box">
                <h3 class="title is-size-3">Billing</h3>
                <p class="subtitle">Manage your billing settings and manage any subscriptions.</p>
                <a class="button is-primary is-outlined">Billing ></a>
            </div>
        </div>
        <div class="column">
            <div class="box">
                <h3 class="title is-size-3">Support</h3>
                <p class="subtitle">Get in touch with Campfire's friendly & attentive support team.</p>
                <a class="button is-primary is-outlined">Support ></a>
            </div>
        </div>
    </div>
</div>
<div class="spacer"></div>
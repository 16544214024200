import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CheckoutRoutingModule } from './checkout-routing.module';
import { ChBotComponent } from './ch-bot/ch-bot.component';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ChGiftComponent } from './ch-gift/ch-gift.component';


@NgModule({
  declarations: [ChBotComponent, ChGiftComponent],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    CheckoutRoutingModule,
  ]
})
export class CheckoutModule { }

<div class="container">
    <br>
    <div class="step-3 animated fadeInUp">
        <h3 class="title is-size-1">Select a plan.</h3>
        <h5 class="subtitle is-size-4">You are purchasing <span class="has-text-primary">a custom Discord bot.</span>
        </h5>

        <div class="columns has-text-centered">
            <div class="column">
                <div class="box">
                    <p>1 Month</p>
                    <hr>
                    <h2 class="title is-size-2">$4.99</h2>
                    <h4 class="subtitle is-size-4">per month</h4>

                    <a class="button is-fullwidth" [class.is-primary]="plan === 0 || plan === 1"
                        (click)="changePlan(1)">Get 1 Month Plan</a>
                    <hr>
                    <p>7 day money back guarantee</p>
                </div>
            </div>

            <div class="column rel-pos">
                <p class="plan-header">Save 35%</p>
                <div class="box is-highlighted">
                    <br>
                    <p>12 Months + 3 Free Months</p>
                    <hr>
                    <h2 class="title is-size-2">$4.00</h2>
                    <h4 class="subtitle is-size-4">per month</h4>

                    <a class="button is-fullwidth" [class.is-primary]="plan === 0 || plan === 2"
                        (click)="changePlan(2)">Get 15 Month Plan</a>
                    <hr>
                    <p><del class="has-text-primary">$74.85</del>$48.00 billed every 15 months</p>
                    <p>7 day money back guarantee</p>
                </div>
            </div>

            <div class="column">
                <div class="box">
                    <p>6 Months</p>
                    <hr>
                    <h2 class="title is-size-2">$4.56</h2>
                    <h4 class="subtitle is-size-4">per month</h4>

                    <p style="margin-bottom: 10px; color: rgb(255, 71, 71);"><b>Limited time: Pay by card and get 14 days free.</b></p>

                    <a class="button is-fullwidth" [class.is-primary]="plan === 0 || plan === 3"
                        (click)="changePlan(3)">Get 6 Month Plan</a>
                    <hr>
                    <p><del class="has-text-primary">$29.94</del>$27.36 billed every 6 months</p>
                    <p>7 day money back guarantee</p>
                </div>
            </div>
        </div>

        <div *ngIf="plan != 0" id="planBillingContainer">
            <hr>

            <div *ngIf="loadingPayment" class="has-text-centered">
                <img src="../../../assets/spinner.svg" width="100px">
                <p>Loading</p>
            </div>

            <div *ngIf="!loadingPayment">
                <h3 class="title is-size-1">Payment.</h3>
                <h5 class="subtitle is-size-4">Now, your payment details.</h5>

                <div class="columns">
                    <div class="column is-3">
                        <div class="box click-box" (click)="changePaymentMethod('stripe')">
                            <p class="has-text-weight-bold">Credit/Debit Cards</p>

                            <span class="icon is-large" style="color: #292e97;"><i
                                    class="fab fa-cc-visa fa-2x"></i></span>
                            <span class="icon is-large" style="color: #eb001b;"><i
                                    class="fab fa-cc-mastercard fa-2x"></i></span>
                            <span class="icon is-large" style="color: #f07e22;"><i
                                    class="fab fa-cc-discover fa-2x"></i></span>
                            <span class="icon is-large" style="color: #016fd0;"><i
                                    class="fab fa-cc-amex fa-2x"></i></span>
                            <span class="icon is-large" style="color: #319544;"><i
                                    class="fab fa-cc-jcb fa-2x"></i></span>
                        </div>

                        <div class="box click-box" (click)="changePaymentMethod('paypal')">
                            <p class="has-text-weight-bold">PayPal</p>

                            <span class="icon is-large" style="color: #009cde;"><i
                                    class="fab fa-cc-paypal fa-2x"></i></span>
                        </div>
                    </div>

                    <div class="column">
                        <div class="box">
                                <div [class.is-hidden]="paymentMethod !== 'stripe'">
                                    <h3 class="title -is-size-4">Pay by Card</h3>

                                    <!--<div class="notification is-info" *ngIf="plan != 3">
                                        Use code <b>HOLIDAYS</b> for 40% off. Sale ends 24th December 2020 23:59 UTC
                                    </div>-->

                                    <a class="button is-primary is-fullwidth" (click)="initCheckoutSession()">Purchase ></a>
                                </div>


                                <div [class.is-hidden]="paymentMethod !== 'paypal'">
                                    <h3 class="title -is-size-4">Pay by PayPal</h3>

                                    <div id="paypal-drop-in-plan1" [class.is-hidden]="plan !== 1"></div>
                                    <div id="paypal-drop-in-plan2"  [class.is-hidden]="plan !== 2"></div>
                                    <div id="paypal-drop-in-plan3"  [class.is-hidden]="plan !== 3"></div>

                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <br>
</div>
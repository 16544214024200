<div class="hero is-light">
    <div class="hero-body">
        <div class="container has-text-centered">
            <h1 class="title is-size-1">Why pick <span class="has-text-primary">Campfire</span>?</h1>
            <h3 class="subtitle is-size-4">Good question. There are so many options. But, we stand out.</h3>
        </div>
    </div>
</div>

<div class="hero is-medium cf-bg-splash-ind">
    <div class="hero-body">
        <div class="container">
            <h1 class="title is-size-2">Campfire's bots are custom.</h1>
            <h3 class="subtitle is-size-6">Now that's you. Bots deserve to look unique.<br>Stand out from the crowd with Campfire.</h3>
        </div>
    </div>
</div>

<div class="hero is-light">
    <div class="hero-body">
        <div class="container">
            <h1 class="title is-size-2">Try yours today. <span class="has-text-primary">Love it in 7 days, or your money back.</span></h1>
            <h3 class="subtitle is-size-6">Fall in love with our easy-to-use platform.</h3>

            <a routerLink="/app/bot/setup/create" class="button is-primary" *ngIf="user">Create</a>
            <p *ngIf="!user">Log in to create. The log in button is in the top right.</p>
        </div>
    </div>
</div>
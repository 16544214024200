import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { ChBotComponent } from './ch-bot/ch-bot.component';
import { ChGiftComponent } from './ch-gift/ch-gift.component';


const routes: Routes = [
  { path: 'bot/:id', component: ChBotComponent, canActivate: [AuthGuard]},
  { path: 'gift/create', component: ChGiftComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CheckoutRoutingModule { }

<div class="hero is-light is-medium">
    <div class="hero-body">
        <div class="container">
            <div *ngIf="!user">
                <h1 class="title hero-w is-size-1-touch">We're <span class="has-text-primary">Campfire</span></h1>
                <h3 class="subtitle is-size-2 is-size-3-touch">The home of <span class="has-text-primary">custom Discord Roblox bots</span>.</h3>
                <p>No coding, no managing servers, no stress.</p>
            </div>

            <div *ngIf="user">
                <h1 class="title hero-w is-size-1-touch">Hello! This is <span class="has-text-primary">Campfire</span></h1>
                <h3 class="subtitle is-size-2 is-size-3-touch">The highly-customisable <span class="has-text-primary">custom-account Discord Roblox bot</span> platform.</h3>
            </div>

            <div class="code" *ngIf="display == 'partner'">
                <h1 class="title hero-w is-size-1-touch"><span class="shooting-star">{{ partner }}</span> uses Campfire to power their
                    server.</h1>
                <h3 class="subtitle is-size-2 is-size-3-touch">You can too! <span class="has-text-primary">You'll
                        fall in love with it.</span></h3>
            </div>

            <div class="spacer"></div>

            <div id="wistia-embed">
                <span class="wistia_embed wistia_async_3ihsw3n2v4 popover=true popoverContent=link"
                    style="display:inline;position:relative"><a href="#" class="button is-primary"
                        (click)="watchVideo()"><span class="icon"><i class="fas fa-play"></i></span><span>Watch the
                            video</span></a></span>
            </div>

        </div>
    </div>
</div>

<div class="hero is-small">
    <div class="hero-body">
        <div class="container">
            <br>
            <h4 class="title is-size-4 has-text-centered">Trusted by great groups</h4>
            <div class="columns has-text-centered">
                <div class="column is-3">
                    <img src="../../assets/marketing/Koala-Cafe.png" alt="Koala Cafe" class="brand-logo">
                </div>

                <div class="column is-3">
                    <img src="../../assets/marketing/Bloxworld-Logo.png" alt="Bloxworld" class="brand-logo">
                </div>

                <div class="column is-3">
                    <img src="../../assets/marketing/Emerald.png" alt="Emerald Theatre" class="brand-logo">
                </div>

                <div class="column is-3">
                    <img src="../../assets/marketing/Blendease-Logo.png" alt="Blendease" class="brand-logo">
                </div>
            </div>
            <div class="columns has-text-centered">
                <div class="column is-4">
                    <img src="../../assets/marketing/Bloxway.png" alt="Bloxway" class="brand-logo">
                </div>

                <div class="column is-4">
                    <img src="../../assets/marketing/Pastriez-Logo.png" alt="Pastriez Bakery" class="brand-logo">
                </div>

                <div class="column is-4">
                    <img src="../../assets/marketing/Stareso-Logo.png" alt="Stareso" class="brand-logo">
                </div>
            </div>

            <div class="columns has-text-centered">
                <div class="column is-3">
                    <img src="../../assets/marketing/Tiger-Logo.png" alt="Tiger" class="brand-logo">
                </div>

                <div class="column is-3">
                    <img src="../../assets/marketing/Kohau-Logo.png" alt="Kohau" class="brand-logo">
                </div>

                <div class="column is-3">
                    <img src="../../assets/marketing/Solera-Logo.png" alt="Solera Hotels & Resorts" class="brand-logo">
                </div>

                <div class="column is-3">
                    <img src="../../assets/marketing/ENLITE-Logo.png" alt="ENLITE" class="brand-logo">
                </div>
            </div>

            <div class="has-text-centered">
                <div id="carbonAdHolder" style="margin: 0 auto;"></div>
            </div>
        </div>
    </div>
</div>

<div class="hero is-light">
    <div class="hero-body">
        <div class="container has-text-centered">
            <div class="columns">
                <div class="column">
                    <span class="icon is-large has-text-primary">
                        <i class="fas fa-cubes fa-3x"></i>
                    </span>
                    <h3 class="title is-size-4">Modular</h3>
                    <p class="subtitle is-size-6">Only use the commands you need</p>
                </div>

                <div class="column">
                    <span class="icon is-large has-text-primary">
                        <i class="fas fa-cogs fa-3x"></i>
                    </span>
                    <h3 class="title is-size-4">Customisable</h3>
                    <p class="subtitle is-size-6">Change the bot to your needs</p>
                </div>

                <div class="column">
                    <span class="icon is-large has-text-primary">
                        <i class="fab fa-node-js fa-3x"></i>
                    </span>
                    <h3 class="title is-size-4">Built with Node.js</h3>
                    <p class="subtitle is-size-6">Powerful simplicity</p>
                </div>

                <div class="column">
                    <span class="icon is-large has-text-primary">
                        <i class="fab fa-digital-ocean fa-3x animated fadeIn" *ngIf="currentHost === 1"></i>
                        <i class="fab fa-aws fa-3x animated fadeIn" *ngIf="currentHost === 2"></i>
                        <i class="fab fa-google fa-3x animated fadeIn" *ngIf="currentHost === 3"></i>
                        <i class="fab fa-linode fa-3x animated fadeIn" *ngIf="currentHost === 4"></i>
                        <i class="fab fa-atlassian fa-3x animated fadeIn" *ngIf="currentHost === 5"></i>
                    </span>
                    <h3 class="title is-size-4">World-class hosting</h3>
                    <p class="subtitle is-size-6">Uptime that you can rely on</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="hero is-light">
    <div class="hero-body">
        <div class="container">
            <h4 class="title is-size-4 has-text-centered">People love our services</h4>
            <div class="main-carousel">
                <div class="carousel-cell">
                    <p>"Campfire has helped my group in many ways, by giving me a Member Counter when I needed it to
                        giving me a Shout Logger for my shouts. They are very awesome and you should try it!"</p>
                    <p><span class="has-text-primary">Noah?#5651</span>, Treatiez'</p>
                </div>
                <div class="carousel-cell">
                    <p>"I've been using Campfire for around a month now. I have found everything I need in the bots and
                        with custom bots recently releasing it makes it that much better. Previously, I had around 10
                        bots in my server to do everything I need and now I have 3. Campfire is cleaner to use just
                        easier as everything can be done from one website versus having to go to one website to change
                        giveaway settings then going to the moderation website. Campfire simply makes life easier."</p>
                    <p><span class="has-text-primary">jamey#0001</span>, Sweetology</p>
                </div>
                <div class="carousel-cell">
                    <p>"I just love how everything is in one bot! Like with verification, ticketing and much more this
                        bot is the best!"</p>
                    <p><span class="has-text-primary">aidxn#3883</span>, Istanbail</p>
                </div>
                <div class="carousel-cell">
                    <p>"Campfire offers so many amazing services, and loads of them don't even take anything out of your
                        wallet!"</p>
                    <p><span class="has-text-primary">$ben#1661</span>, Teppanyaki</p>
                </div>
                <div class="carousel-cell">
                    <p>"Campfire is perfect, their customer support is very helpful and friendly."</p>
                    <p><span class="has-text-primary">lewis#7292</span>, Sidesa</p>
                </div>
                <div class="carousel-cell">
                    <p>"Campfire has provided me with everything that I could ever need. The $4 replaces Bloxlink Pro,
                        Rover Pro, etc. so much better for such a cheaper price. The amount of services they provide for
                        free is insane and it is really helping us, Sweetology, grow as a company. I honestly don't know
                        what we would do without Campfire."</p>
                    <p><span class="has-text-primary">jamey#0001</span>, Sweetology</p>
                </div>

                <div class="carousel-cell">
                    <p>"I would highly recommend Campfire. Campfire provides a free aspect of a Member Count Proxy and a
                        Group Shout Proxy, and yes, you heard that right, FOR FREE. This is an absolutely amazing
                        service that is for free of charge and requires no payment whatsoever. I'd highly recommend
                        Campfire HQ, they have supported me with the services with my business growth several times and
                        I cannot thank them enough."</p>
                    <p><span class="has-text-primary">dave;#1000</span></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="hero is-small">
    <div class="hero-body">
        <div class="container has-text-centered">
            <div class="columns">
                <div class="column">
                    <h1 class="title is-3" [countUp]="914" [options]="counters">0</h1>
                    <h3 class="subtitle is-size-4">Custom Bots</h3>
                </div>

                <!--<div class="column">
                    <h1 class="title is-3" [countUp]="17571" [options]="counters">0</h1>
                    <h3 class="subtitle is-size-4">Member Counters</h3>
                </div>-->
            </div>

            <div class="columns">
                <div class="column">
                    <h1 class="title is-3" [countUp]="6" [options]="counters">0</h1>
                    <h3 class="subtitle is-size-4">Datacentre Regions</h3>
                </div>

                <div class="column">
                    <h1 class="title is-3" [countUp]="99.77" [options]="uptime">0</h1>
                    <h3 class="subtitle is-size-4">Uptime (90 days)</h3>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="hero is-primary">
    <div class="hero-body">
        <div class="container">
            <h4 class="title is-size-2 has-text-centered">Our tools</h4>
            <div class="columns">
                <div class="column is-4">
                    <div class="box has-text-dark">
                        <h4 class="title is-size-3"><span class="icon is-large has-text-primary"><i
                                    class="fas fa-project-diagram"></i></span> <span>Ranking</span></h4>
                        <p class="subtitle">Rank group members programmatically</p>
                    </div>
                    <div class="box has-text-dark">
                        <h4 class="title is-size-3"><span class="icon is-large has-text-primary"><i
                                    class="fas fa-paste"></i></span> <span>Pastebook</span></h4>
                        <p class="subtitle">Store your greetings and more.</p>
                    </div>
                    <div class="box has-text-dark">
                        <h4 class="title is-size-3"><span class="icon is-large has-text-primary"><i
                                    class="fas fa-globe-americas"></i></span> <span>Global Infastructure</span></h4>
                        <p class="subtitle">Our global infastructure powers our bots</p>
                    </div>
                </div>
                <div class="column is-flex">
                    <div class="box has-text-dark" style="flex: 1;">
                        <h4 class="title is-size-3"><span class="icon is-large has-text-primary"><i
                                    class="fas fa-robot"></i></span> <span>Bot</span></h4>
                        <div class="discord-message">
                            <img src="{{ user?.photoURL || '../../assets/marketing/889bc07288510aed5d4c835296ec747c.png' }}"
                                alt="Avatar" width="60px" height="60px" class="is-rounded">
                            <div class="message-holder">
                                <div class="message-details">
                                    <p>
                                        <span class="user-title">{{ user?.displayName || 'Bob' }}</span>
                                        <span class="timestamp">Today at 12:46pm</span>
                                    </p>
                                </div>
                                <p>c!ping</p>
                            </div>
                        </div>

                        <div class="discord-message">
                            <img src="../../assets/marketing/Club-Bot.png" alt="Avatar" width="60px" height="60px"
                                class="is-rounded">
                            <div class="message-holder">
                                <div class="message-details">
                                    <p>
                                        <span class="user-title">Club Bot</span>
                                        <span class="bot-tag">BOT</span>
                                        <span class="timestamp">Today at 12:46pm</span>
                                    </p>
                                </div>
                                <p><i class="twa twa-bellhop"></i> <b>Pong!</b> It took me 204ms to get back to you!</p>
                            </div>
                        </div>

                        <hr>

                        <p>Our custom Discord bots make it easy to come off professional to your community. We've built
                            them to be reliable, stable and easy-to-use. There's no need to spend time creating your own
                            Discord bot to handle your group - we'll do it all for you.</p>
                        <br>
                        <a *ngIf="user" routerLink="/app/bot/setup/create" class="button is-primary">Try it out</a>
                        <a *ngIf="!user" routerLink="/products/bot" class="button is-primary">Learn more</a>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hero-foot" *ngIf="display === 'saving'">
        <p>* Based on a cost to performance metric and the savings on the cost based on a deveoper charging $30.</p>
    </div>
</div>

<div class="hero is-light">
    <div class="hero-body">
        <div class="container">
            <h4 class="title is-size-2 has-text-centered">What can our bots do?</h4>

            <div class="box">
                <h4 class="title is-size-3"><span class="icon is-large has-text-primary"><i
                            class="fas fa-user-check"></i></span> <span>Verification</span></h4>
                <p>Our bots come with verification right out of the box. It's as good as any other bot.</p>
            </div>

            <div class="columns">
                <div class="column is-flex">
                    <div class="box">
                        <h4 class="title is-size-3"><span class="icon is-large has-text-primary"><i
                                    class="fas fa-sign-in"></i></span> <span>Welcomer</span></h4>
                        <p>Welcome new users to your Discord with friendly and customisable messages.</p>
                    </div>
                </div>

                <div class="column is-flex">
                    <div class="box">
                        <h4 class="title is-size-3"><span class="icon is-large has-text-primary"><i
                                    class="fas fa-box-ballot"></i></span> <span>Suggestions</span></h4>
                        <p>Get user's feedback with our built in suggestions command. Users can vote on other users
                            suggestions.</p>
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="column is-flex">
                    <div class="box">
                        <h4 class="title is-size-3"><span class="icon is-large has-text-primary"><i
                                    class="fas fa-globe"></i></span> <span>Timezones</span></h4>
                        <p>Convert timezones with our time command. Get the time of any place in the world.</p>
                    </div>
                </div>

                <div class="column is-flex">
                    <div class="box">
                        <h4 class="title is-size-3"><span class="icon is-large has-text-primary"><i
                                    class="fas fa-archive"></i></span> <span>Logging</span></h4>
                        <p>Log joins, leaves, bans, deletions, edits, and voice channel movement.</p>
                    </div>
                </div>

                <div class="column is-flex">
                    <div class="box">
                        <h4 class="title is-size-3"><span class="icon is-large has-text-primary"><i
                                    class="fas fa-clock"></i></span> <span>Sessions</span></h4>
                        <p>Keep things organised with session announcements. Customisable to the core.</p>
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="column is-flex">
                    <div class="box">
                        <h4 class="title is-size-3"><span class="icon is-large has-text-primary"><i
                                    class="fas fa-palette"></i></span> <span>Colours</span></h4>
                        <p>Aspiring artist? Get a random colour with the colour command.</p>
                    </div>
                </div>

                <div class="column is-flex">
                    <div class="box">
                        <h4 class="title is-size-3"><span class="icon is-large has-text-primary"><i
                                    class="fas fa-calculator"></i></span> <span>Math</span></h4>
                        <p>Calculate math equations with your bot. Your homework just got a whole lot easier.</p>
                    </div>
                </div>

                <div class="column is-flex">
                    <div class="box" style="flex: 1;">
                        <h4 class="title is-size-3"><span class="icon is-large has-text-primary"><i
                                    class="fas fa-stopwatch"></i></span> <span>Timer</span></h4>
                        <p>Keep track of time - with a timer. You can keep timers running to remind you to do things or
                            for events.</p>
                    </div>
                </div>
            </div>

            <div class="box">
                <h4 class="title is-size-3"><span class="icon is-large has-text-primary"><i
                            class="fas fa-hammer-war"></i></span> <span>Moderation</span></h4>
                <p>Advanced moderation system, which is highly customisable to the role.</p>
            </div>

            <div class="columns">
                <div class="column is-flex">
                    <div class="box">
                        <h4 class="title is-size-3"><span class="icon is-large has-text-primary"><i
                                    class="fas fa-thunderstorm"></i></span> <span>Weather</span></h4>
                        <p>Get the weather for anywhere in the world. Is it raining in New York? Find out.</p>
                    </div>
                </div>

                <div class="column is-flex">
                    <div class="box">
                        <h4 class="title is-size-3"><span class="icon is-large has-text-primary"><i
                                    class="fas fa-code"></i></span> <span>Custom Commands</span></h4>
                        <p>Custom commands that are easy to build for non-programmers, and extremely versatile for
                            developers</p>
                    </div>
                </div>

                <div class="column is-flex">
                    <div class="box" style="flex: 1;">
                        <h4 class="title is-size-3"><span class="icon is-large has-text-primary"><i
                                    class="fas fa-shapes"></i></span> <span>Lots more</span></h4>
                        <p>Our bots are packed with features. There are a lot more of where these came from.</p>
                    </div>
                </div>
            </div>

            <div class="has-text-centered">
                <a routerLink="/help" class="button is-dark is-outlined">View all commands ></a>
            </div>
        </div>
    </div>
</div>

<!-- <div class="hero is-primary">
    <div class="hero-body">
        <div class="container has-text-centered">
            <h4 class="title is-size-2 has-text-centered">Get your custom bot today</h4>
            <p class="subtitle has-text-centered">Just $4.99 a month, with a 7 day money back guarantee.</p>

            <a class="button is-light" routerLink="/app/bot/setup/create">Purchase</a>

            <div class="spacer"></div>

            <hr>

            <div class="spacer"></div>

            <p class="subtitle has-text-centered"><b>Don't have $4.99 to spend on us?</b> Have Discord Nitro? Use your
                two boosts on our server and you'll have your bot for as long as you boost.</p>

            <div class="has-text-centered">
                <a class="button is-light is-outlined" href="https://discord.gg/tHrSsPB" target="_blank">Read More ></a>
            </div>
        </div>
    </div>
</div> -->

<div class="hero cf-discord">
    <div class="hero-body">
        <div class="container">
            <div class="columns is-vcentered">
                <div class="column">
                    <p>Talk to sales, join the Campfire HQ Discord</p>
                </div>

                <div class="column has-text-right">
                    <a href="https://discord.gg/tHrSsPB" target="_blank" class="button is-light"><span class="icon"><i
                                class="fab fa-discord"></i></span> <span>Join Discord</span></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="hero is-medium is-light">
    <div class="hero-body">
        <div class="container">
            <h1 class="title is-size-1"><span class="has-text-primary">Bots should be easy.</span> Why aren't they?</h1>
            <h3 class="subtitle is-size-3">They are now. Our platform takes the hassle out of bot development.</h3>
            <div class="buttons">
                <a class="button is-primary" routerLink="/app/bot/setup" *ngIf="user">Get started</a>
                <p *ngIf="!user">Sign in to get started. The log in button is in the top right.</p>
            </div>
        </div>
    </div>
</div>

<div class="hero is-primary">
    <div class="hero-body">
        <div class="container has-text-centered">
            <h1 class="title is-size-3">It's easy to set up</h1>
            <h3 class="subtitle is-size-4">Get running in just a few clicks</h3>

            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/WC25iOCR-1I" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>
</div>

<div class="hero">
    <div class="hero-body">
        <div class="container">
            <div class="columns">
                <div class="column is-7" style="display: flex; justify-content: center; align-items: center;">
                    <div class="content">
                        <h1 class="title is-size-2">Custom Verification</h1>
                        <p class="subtitle">Campfire bots come with custom verification, right out of the box.</p>
                        <hr>
                        <h3 class="title is-size-3 no-mb"><span class="icon is-large has-text-primary"><i class="fas fa-fighter-jet"></i></span> Quick to set up</h3>
                        <h3 class="title is-size-3 no-mb"><span class="icon is-large has-text-primary"><i class="fas fa-browser"></i></span> Entirely managed via web interface</h3>
                        <h3 class="title is-size-3 no-mb"><span class="icon is-large has-text-primary"><i class="fas fa-transporter-2"></i></span> State of the art, cutting edge tech</h3>
                    </div>
                </div>
                <div class="column is-hidden-touch" style="display: flex; justify-content: center; align-items: center;">
                    <img src="https://static.campfirehq.net/Discord_SqHnyI5ScF.png" alt="Custom Verification">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="hero is-light">
    <div class="hero-body">
        <div class="container">
            <div class="columns">
                <div class="column is-7" style="display: flex; justify-content: center; align-items: center;">
                    <div class="content">
                        <h1 class="title is-size-2">Feature-rich web dashboard</h1>
                        <p class="subtitle">Our web dashboard is quick and easy. You'll love it.</p>
                        <hr class="darker">
                        <h3 class="title is-size-3 no-mb"><span class="icon is-large has-text-primary"><i class="fas fa-route"></i></span> Easy to navigate</h3>
                        <h3 class="title is-size-3 no-mb"><span class="icon is-large has-text-primary"><i class="fas fa-cubes"></i></span> Modular. Enable only what you need</h3>
                        <h3 class="title is-size-3 no-mb"><span class="icon is-large has-text-primary"><i class="fas fa-rabbit-fast"></i></span> Runs on blazing fast Google servers</h3>
                    </div>
                </div>
                <div class="column is-hidden-touch" style="display: flex; justify-content: center; align-items: center;">
                    <img src="https://static.campfirehq.net/chrome_BVn9hapM1l.png" alt="Web Dashboard">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="hero">
    <div class="hero-body">
        <div class="container">
            <h1 class="title is-size-2">Useful utility commands</h1>
            <p class="subtitle">We've built in a load of useful commands for you to use</p>
            <hr>
            <div class="columns">
                <div class="column">
                    <div class="content">
                        <h3 class="title is-size-3 no-mb"><span class="icon is-large has-text-primary"><i class="fas fa-palette"></i></span> Random Colour</h3>
                        <h3 class="title is-size-3 no-mb"><span class="icon is-large has-text-primary"><i class="fas fa-icons-alt"></i></span> Emojify</h3>
                        <h3 class="title is-size-3 no-mb"><span class="icon is-large has-text-primary"><i class="fas fa-function"></i></span> Math</h3>
                    </div>
                </div>
                <div class="column">
                    <div class="content">
                        <h3 class="title is-size-3 no-mb"><span class="icon is-large has-text-primary"><i class="fab fa-spotify"></i></span> Spotify</h3>
                        <h3 class="title is-size-3 no-mb"><span class="icon is-large has-text-primary"><i class="fas fa-box-ballot"></i></span> Suggestions</h3>
                        <h3 class="title is-size-3 no-mb"><span class="icon is-large has-text-primary"><i class="fas fa-globe-europe"></i></span> Timezone</h3>
                    </div>
                </div>

                <div class="column">
                    <div class="content">
                        <h3 class="title is-size-3 no-mb"><span class="icon is-large has-text-primary"><i class="fas fa-alarm-clock"></i></span> Timer</h3>
                        <h3 class="title is-size-3 no-mb"><span class="icon is-large has-text-primary"><i class="fas fa-thunderstorm"></i></span> Weather</h3>
                        <h3 class="title is-size-3 no-mb"><span class="icon is-large has-text-primary"><i class="fas fa-sparkles"></i></span> More coming soon...</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="hero is-medium is-light">
    <div class="hero-body">
        <div class="container has-text-centered">
            <h1 class="title is-size-1">What are you waiting for?</h1>
            <h3 class="subtitle is-size-3">Get a custom Discord bot, on the double.</h3>
            <a class="button is-primary" routerLink="/app/bot/setup" *ngIf="user">Get started</a>
            <p *ngIf="!user">Sign in to get started. The log in button is in the top right.</p>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http'

@Component({
  selector: 'app-status-strip',
  templateUrl: './status-strip.component.html',
  styleUrls: ['./status-strip.component.css']
})
export class StatusStripComponent implements OnInit {

  constructor(private http: HttpClient) { }

  showStrip: boolean;
  statusType: string;
  statusMessage: string;

  ngOnInit(): void {
    this.http.get<any>('https://741dddw78hx7.statuspage.io/api/v2/summary.json').subscribe(data => {
      if(data.status.indicator !== 'none') {
        this.showStrip = true;
        this.statusType = data.status.description;
        this.statusMessage = data.incidents[0].name;
      }
    })
  }

}

<section class="hero is-fullheight is-light">
    <div class="hero-body">
        <div class="container has-text-centered">
            <h1 class="title is-size-1">404</h1>
            <h1 class="subtitle is-size-4">Huh? We hit an error trying to load this page.</h1>
            <p>It might have been moved or deleted. Or it may have never existed? Who knows?</p><br>
            <a routerLink="/" class="button is-light is-outlined is-small" style="margin-right: 5px;">Home</a>
            <a href="https://discord.gg/tHrSsPB" class="button is-light is-outlined is-small">Support Server</a>
        </div>
    </div>
</section>
<img src="../../assets/boi.png" class="boi">
<div class="hero is-light">
    <div class="hero-body">
        <div class="container">
            <div class="columns">
                <div class="column has-text-soft-grey is-2">
                    <img src="../../assets/branding/logos/Campfire-Wordmark-Soft-Grey.svg" alt="Campfire" width="120px">
                    <p>Copyright &copy; 2018-2021</p>
                    <p>All Rights Reserved</p>
                </div>

                <div class="column is-3">
                    <h1 class="title is-size-4" style="margin-bottom: 3px;">Products</h1>
                    <ul>
                        <li><a routerLink="/products/bot">Bot</a></li>
                        <li><a href="https://booster.campfirebot.xyz">Booster</a></li>
                        <li><a href="https://docs.campfirehq.net/en/ranking">Ranking</a></li>
                    </ul>
                </div>

                <div class="column is-3">
                    <h1 class="title is-size-4" style="margin-bottom: 3px;">Legal</h1>
                    <ul>
                        <li><a href="https://www.notion.so/campfirehq/Terms-of-Service-a86dab7c0a324bbaa9f7242bbaeb7265" target="_blank">Terms of Service</a></li>
                        <li><a href="https://www.notion.so/campfirehq/Privacy-Policy-9b65b8e5505e4cc0a5ab5cfd88a39a08" target="_blank">Privacy Policy</a></li>
                        <li><a href="https://www.notion.so/campfirehq/Modern-Slavery-Statement-23e71157635040bba82c8b6f7d97928d" target="_blank">Modern Slavery Statement</a></li>
                    </ul>
                </div>

                <div class="column is-3">
                    <h1 class="title is-size-4" style="margin-bottom: 3px;">About</h1>
                    <ul>
                        <li><a href="https://www.notion.so/campfirehq/Our-Mission-876a3e7fcdb9487b95d0363ecddf833e" target="_blank">Our mission</a></li>
                        <!--<li><a href="https://blog.campfirehq.net" target="_blank">Blog</a></li>-->
                        <!--<li><a href="https://campfireforms.typeform.com/to/EZ3AOH" target="_blank">Jobs</a></li>-->
                    </ul>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <div class="social-icons">
                        <a class="social-icon" href="https://twitter.com/campfire_hq" target="_blank">
                            <span class="icon"><i class="fab fa-twitter"></i></span>
                        </a>
        
                        <a class="social-icon" href="https://www.instagram.com/campfire_team/" target="_blank">
                            <span class="icon"><i class="fab fa-instagram"></i></span>
                        </a>
        
                        <a class="social-icon" href="https://discord.gg/tHrSsPB" target="_blank">
                            <span class="icon"><i class="fab fa-discord"></i></span>
                        </a>
        
                        <a class="social-icon" href="https://www.youtube.com/channel/UCHfGFXXiORAHZ5EFcnN3L4Q" target="_blank">
                            <span class="icon"><i class="fab fa-youtube"></i></span>
                        </a>
                    </div>
                </div>
            </div>

            <p class="has-text-soft-grey" style="margin-bottom: 5px;">Built with <i class="twa twa-purple_heart"></i> and <i class="twa twa-coffee"></i> in <i class="twa twa-flag_gb"></i> Great Britain, <i class="twa twa-flag_se"></i> Sweden and <i class="twa twa-flag_au"></i> Australia by Team Campfire</p>

            <div class="buttons">
                <a href="https://www.buymeacoffee.com/campfire" target="_blank" class="button is-small"><span class="icon"><i class="twa twa-coffee"></i></span> <span>Buy our Developers a Coffee</span></a>
                <a href="https://patreon.com/campfirebot" target="_blank" class="button is-small cf-patreon"><span class="icon"><i class="fab fa-patreon"></i></span> <span>Become a Patron</span></a>
            </div>

            <br>
        </div>
    </div>
</div>
import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators'
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

import * as Sentry from "@sentry/browser";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSource = new BehaviorSubject('');
  currentUser = this.userSource.asObservable();

  constructor(
    private afAuth: AngularFireAuth,
    private funcs: AngularFireFunctions,
    private db: AngularFirestore,
    private router: Router,
    private ngZone: NgZone,
    private http: HttpClient
  ) { }

  justLoggedIn = false;

  login() {
    window.open(
      'https://discordapp.com/oauth2/authorize?client_id=693553274556514340&redirect_uri=https://api.campfirehq.net/user/login&response_type=code&scope=identify%20guilds',
      'Campfire HQ',
      'menubar=no, width=1000,height=720,location=no,resizable=no,scrollbars=yes,status=no'
    )
  }

  public isAuthenticated() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  pushToken(tokenData) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.auth.signInWithCustomToken(tokenData.auth).then(
        res => {
          res.user.updateProfile({
            displayName: tokenData.username,
            photoURL: `https://cdn.discordapp.com/avatars/${res.user.uid}/${tokenData.avatar}.png`
          }).then(() => {
            window.localStorage.setItem('cName', tokenData.username);
            window.localStorage.setItem('cID', res.user.uid);
            window.localStorage.setItem('spin', new Date().getTime().toString());
            this.justLoggedIn = true;
            this.ngZone.run(() => this.router.navigate(['app/bot/setup']));
            resolve(res);
          })
        },
        err => {
          if (err.code === 'auth/user-disabled') {
            Swal.fire('Account Disabled', 'Your account has been disabled. Please contact Support.', 'info');
          } else {
            reject(err);
          }
        }
      )
    })
  }

  getLoggedInUser() {
    return this.afAuth.authState;
  }

  logout() {
    localStorage.removeItem('cName');
    localStorage.removeItem('cID');
    this.afAuth.auth.signOut();
    this.router.navigate(['/']);
  }

  loginWithEmailAndPassword(email, password) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.auth.signInWithEmailAndPassword(email, password).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      })
    })
  }

  deleteAccount() {
    return new Promise<any>((resolve, reject) => {
      this.getLoggedInUser().subscribe(user => {
        user.delete().then(() => {
          resolve(true);
        }).catch(err => {
          if (err.message === 'This operation is sensitive and requires recent authentication. Log in again before retrying this request.') {
            reject({ message: 'For your security, please login and complete this request again.' })
            setTimeout(() => {
              this.login()
            }, 1500);
          }
        })
      })
    })
  }

  refreshToken() {
    if (this.justLoggedIn === false) {
      if (parseInt(localStorage.getItem('spin')) < new Date().getTime() - 6.048e+8) {

        this.returnUserToken().then(token => {
          this.http.get<any>(`https://api.campfirehq.net/user/refresh`, {
            headers: {
              authorization: token
            }
          }).subscribe(response => {
            window.localStorage.setItem('spin', new Date().getTime().toString());
            this.getLoggedInUser().pipe(first()).subscribe(user => {
              user.updateProfile({
                displayName: response.username,
                photoURL: `https://cdn.discordapp.com/avatars/${user.uid}/${response.avatar}.png`
              })
            })
          })
        })
      } else {
        this.returnUserToken().then(token => {
          this.http.get<any>(`https://api.campfirehq.net/user/update-profile`, {
            headers: {
              authorization: token
            }
          }).subscribe(response => {
            if (response.success == true) {
              this.getLoggedInUser().pipe(first()).subscribe(user => {
                user.updateProfile({
                  displayName: response.username,
                  photoURL: `https://cdn.discordapp.com/avatars/${user.uid}/${response.avatar}.png`
                })
              })
            }
          })
        })
      }
    }
  }

  returnUserToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.getLoggedInUser().pipe(first()).subscribe(user => {
        if (user) {
          user.getIdToken().then(response => {
            resolve(response);
          }).catch(err => {
            reject();
          })
        } else {
          reject();
        }
      })
    })
  }
}

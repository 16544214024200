import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-live-update',
  templateUrl: './live-update.component.html',
  styleUrls: ['./live-update.component.css']
})
export class LiveUpdateComponent implements OnInit {

  constructor(private rtdb: AngularFireDatabase) { }

  showStrip: boolean;
  update: string;

  ngOnInit(): void {
    this.rtdb.object('live').valueChanges().subscribe(data => {
      let parsedData = (data as any);
      if(parsedData.enabled == true) {
        this.showStrip = true;
        this.update = parsedData.message
      } else {
        this.showStrip = false;
      }
    })
  }
}

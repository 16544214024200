import { Component, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { first, filter, map, last } from 'rxjs/operators';
import { AngularFireRemoteConfig, filterFresh, scanToObject, budget } from '@angular/fire/remote-config';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

declare var Flickity: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {

  user: firebase.User;
  display = "process";
  partner: string;

  counters = {
    duration: 5
  }

  uptime = {
    duration: 5,
    suffix: "%",
    decimalPlaces: 2
  }

  currentHost = 1;

  constructor(private service: AuthService, private remoteConfig: AngularFireRemoteConfig, private renderer: Renderer2, private analytics: AngularFireAnalytics, private titleService: Title, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.service.getLoggedInUser().subscribe(user => {
      this.user = user;
    })

    this.route.queryParams.subscribe(params => {
      if(params.partner) {
        this.display = 'partner';
        this.partner = params.partner;
      }
    })

    this.titleService.setTitle(`Home | Campfire HQ`)

    setInterval(() => {
      if(this.currentHost != 5) {
        this.currentHost = this.currentHost + 1;
      } else {
        this.currentHost = 1;
      }
    }, 5000)
  }

  ngAfterViewInit() {
    let script = this.renderer.createElement('script');
    script.src = "https://fast.wistia.com/embed/medias/3ihsw3n2v4.jsonp";
    script.async = true;

    let script2 = this.renderer.createElement('script');
    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;

    this.renderer.appendChild(document.getElementById('wistia-embed'), script);
    this.renderer.appendChild(document.getElementById('wistia-embed'), script2);

    var elem = document.querySelector('.main-carousel');
    var flkty = new Flickity(elem, {
      // options
      cellAlign: 'center',
      wrapAround: true,
      autoPlay: 7500
    });

    let carbon = this.renderer.createElement('script');
    carbon.type = 'text/javascript';
    carbon.src = "//cdn.carbonads.com/carbon.js?serve=CE7I623L&placement=campfirebotxyz";
    carbon.async = true;
    carbon.id = '_carbonads_js'
    this.renderer.appendChild(document.getElementById('carbonAdHolder'), carbon);
  }

  watchVideo() {
    this.analytics.logEvent('watchHeroVideo');
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {
  errorMessageFromFirebase: string;
  user: firebase.User;

  constructor(private service: AuthService) { }

  ngOnInit(): void {
    this.service.getLoggedInUser().subscribe(user => {
      this.user = user;
    })
  }

  signIn(data) {
    this.errorMessageFromFirebase = '';

    const email = data.value.email;
    const password = data.value.password;

    this.service.loginWithEmailAndPassword(email, password).then(res => {
      Swal.fire('Success', 'You\'re now logged in', 'success');
    }, err => {
      this.errorMessageFromFirebase = err.message;
    })
  }

  updateUser(data) {
    this.user.updateProfile({
      displayName: data.value.name
    }).then(() => {
      Swal.fire('Success', 'That\'s done', 'success');
    })
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css']
})
export class CookiesComponent implements OnInit {
  cookieConsent: boolean;

  constructor() { }

  ngOnInit() {
    function getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }

    if (getCookie('cc') != 'true') {
      this.cookieConsent = true;
    }
  }
  
  consent() {
    document.cookie = 'cc=true; expires= Fri, 1 Jan 2030 12:00:00 UTC'

    this.cookieConsent = false;
  }

}

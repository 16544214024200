import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  user: firebase.User;
  greeting: string;

  constructor(private service: AuthService) { }

  ngOnInit(): void {
    this.service.getLoggedInUser().subscribe(user => {
      this.user = user;
    })
    
    const today = new Date();
    const timeNow = today.getHours();
    if (timeNow < 12) {
      this.greeting = 'Good morning';
    }

    if (timeNow >= 12) {
      this.greeting = 'Good afternoon';
    }

    if (timeNow >= 17) {
      this.greeting = 'Good evening';
    }
  }

}

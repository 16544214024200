import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode, ErrorHandler, Injectable } from '@angular/core';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireStorageModule  } from '@angular/fire/storage';
import { AngularFireRemoteConfigModule, SETTINGS  } from '@angular/fire/remote-config';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService  } from '@angular/fire/analytics';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NavComponent } from './nav/nav.component';
import { LoggedInComponent } from './nav/logged-in/logged-in.component';
import { CookiesComponent } from './cookies/cookies.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { StatusStripComponent } from './status-strip/status-strip.component';
import { HttpClientModule } from '@angular/common/http';

import { FormsModule } from '@angular/forms';
import { PasswordComponent } from './auth/password/password.component';
import { ThankYouComponent } from './thank-you/thank-you.component';

import * as Sentry from "@sentry/browser";
import { WhyCampfireComponent } from './why-campfire/why-campfire.component';
import { BotSaleComponent } from './bot-sale/bot-sale.component';
import { ErrorComponent } from './error/error.component';
import { BotHelpComponent } from './bot-help/bot-help.component';
import { LiveUpdateComponent } from './live-update/live-update.component';
import { AngularFireDatabaseModule } from '@angular/fire/database';

import { CountUpModule } from 'ngx-countup';
import { CheckoutModule } from './checkout/checkout.module';
import { SharedModule } from './shared/shared.module';
import { PricingComponent } from './pricing/pricing.component';

Sentry.init({
  dsn: "https://9c53d54947544333a91d2dc8e6a9da0f@o390146.ingest.sentry.io/5231152"
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    console.log(error);
    const eventId = Sentry.captureException(error.originalError || error);
    // Sentry.showReportDialog({  eventId: eventId });
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavComponent,
    LoggedInComponent,
    CookiesComponent,
    FooterComponent,
    DashboardComponent,
    StatusStripComponent,
    PasswordComponent,
    ThankYouComponent,
    WhyCampfireComponent,
    BotSaleComponent,
    ErrorComponent,
    BotHelpComponent,
    LiveUpdateComponent,
    PricingComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    HttpClientModule,
    FormsModule,
    AngularFireRemoteConfigModule,
    AngularFireAnalyticsModule,
    CountUpModule,
    CheckoutModule,
    SharedModule
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: SETTINGS,
      useValue: { minimumFetchIntervalMillis: 10_000 }
    },
    { provide: ErrorHandler, useClass: SentryErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-why-campfire',
  templateUrl: './why-campfire.component.html',
  styleUrls: ['./why-campfire.component.css']
})
export class WhyCampfireComponent implements OnInit {
  user: firebase.User;  

  constructor(private service: AuthService) { }

  ngOnInit(): void {
    this.service.getLoggedInUser().subscribe(user => {
      this.user = user;
    })
  }

}

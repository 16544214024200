import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CommonModule } from '@angular/common';
import { PasswordComponent } from './auth/password/password.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { WhyCampfireComponent } from './why-campfire/why-campfire.component';
import { BotSaleComponent } from './bot-sale/bot-sale.component';
import { ErrorComponent } from './error/error.component';
import { BotHelpComponent } from './bot-help/bot-help.component';
import { AuthGuard } from './auth/auth.guard';
import { PricingComponent } from './pricing/pricing.component';


const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'external/login', component: PasswordComponent},
  { path: 'why-campfire', component: WhyCampfireComponent},
  { path: 'products/bot', component: BotSaleComponent},
  { path: 'pricing', component: PricingComponent },
  { path: 'help', component: BotHelpComponent},
  { path: 'thank-you', component: ThankYouComponent},
  { path: 'app', pathMatch: 'full', redirectTo: 'app/bot'},
  { path: 'app/bot', loadChildren: () => import('./bot/bot-routing.module').then(m => m.BotRoutingModule)},
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard-routing.module').then(m => m.DashboardRoutingModule)},
  { path: 'pastebook', loadChildren: () => import('./pastebook/pastebook-routing.module').then(m => m.PastebookRoutingModule)},
  //{ path: 'members', loadChildren: () => import('./member-counting/member-counting-routing.module').then(m => m.MemberCountingRoutingModule)},
  // { path: 'shouts', loadChildren: () => import('./group-shout/group-shout-routing.module').then(m => m.GroupShoutRoutingModule)},
  { path: 'account', loadChildren: () => import('./account/account-routing.module').then(m => m.AccountRoutingModule)},
  { path: 'products', loadChildren: () => import('./products/product-routing.module').then(m => m.ProductRoutingModule)},
  { path: 'sapling', loadChildren: () => import('./sapling/sapling-routing.module').then(m => m.SaplingRoutingModule)},
  { path: 'sprout', canActivate: [AuthGuard], loadChildren: () => import('./sprout/sprout-routing.module').then(m => m.SproutRoutingModule)},
  // { path: 'checkout', loadChildren: () => import('./checkout/checkout-routing.module').then(m => m.CheckoutRoutingModule)},
  {path: '**', component: ErrorComponent}
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

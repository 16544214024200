import { Component, OnInit, AfterViewInit, Renderer2, Input } from '@angular/core';

@Component({
  selector: 'app-carbon-ad',
  templateUrl: './carbon-ad.component.html',
  styleUrls: ['./carbon-ad.component.css']
})
export class CarbonAdComponent implements OnInit, AfterViewInit {
  @Input('pulledRight') pulledRight: boolean = true;

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    console.log('📺 Loading Ad')
  }

  ngAfterViewInit() {
    let script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = "//cdn.carbonads.com/carbon.js?serve=CESI4KQN&placement=campfirehqnet";
    script.async = true;
    script.id = '_carbonads_js'
    this.renderer.appendChild(document.getElementById('carbonAdHolder'), script);
  }
}

<div class="hero is-light">
    <div class="hero-body">
        <div class="container">
            <div class="columns">
                <div class="column">
                    <h2 class="title is-size-1">Help</h2>
                    <h2 class="subtitle is-size-3">Assistance with all of our commands</h2>
                </div>

                <div class="column is-2">
                    <app-carbon-ad></app-carbon-ad>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="spacer"></div>
    <div class="content">
        <p>All of Campfire's commands are extremely <b>modular on a server-by-server basis</b>. This means that some commands are available, and some aren't - it's up to that specific <b>server's management to enable each command.</b></p>

        <p>Each command below is tagged with the module it belongs to.</p>
    </div>

    <hr>

    <div class="table-container">
        <table class="table is-bordered is-hoverable">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Usage</th>
                    <th>Descriptor</th>
                    <th>Permissions?</th>
                    <th>Module</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td><code>c!!help</code></td>
                    <td><code>c!!help</code></td>
                    <td>Takes you to this page. Heya! <i class="twa twa-wave"></i></td>
                    <td>Public</td>
                    <td><span class="tag is-light is-medium">N/A</span></td>
                </tr>

                <tr>
                    <td><code>c!!verify</code></td>
                    <td><code>c!!verify</code></td>
                    <td>Verify your Roblox account to your Discord.</td>
                    <td>Public</td>
                    <td><span class="tag cf-verification is-medium">Verification</span></td>
                </tr>

                <tr>
                    <td><code>c!!update [user?]</code></td>
                    <td><code>c!!update @Tato</code></td>
                    <td>Update users roles and username.</td>
                    <td>Public for self<br>Manage Server Permission to update other users</td>
                    <td><span class="tag cf-verification is-medium">Verification</span></td>
                </tr>

                <tr>
                    <td><code>c!!reverify</code></td>
                    <td><code>c!!reverify</code></td>
                    <td>Update users roles and username.</td>
                    <td>Public for self<br>Manage Server Permission to update other users</td>
                    <td><span class="tag cf-verification is-medium">Verification</span></td>
                </tr>

                <tr>
                    <td><code>c!!switchaccount [roblox-id]</code></td>
                    <td><code>c!!switchaccount [roblox-id]</code></td>
                    <td>Switch your user account.</td>
                    <td>Public</td>
                    <td><span class="tag cf-verification is-medium">Verification</span></td>
                </tr>

                <tr>
                    <td><code>c!!whois [user?]</code></td>
                    <td><code>c!!whois @Bruno</code></td>
                    <td>Get into for a user</td>
                    <td>Public</td>
                    <td><span class="tag cf-verification is-medium">Verification</span></td>
                </tr>

                <tr>
                    <td><code>c!!ptag [tag]</code></td>
                    <td><code>c!!ptag HR</code></td>
                    <td>Sets personal tag</td>
                    <td>Coming soon</td>
                    <td><span class="tag cf-soon is-medium">Coming soon</span></td>
                </tr>

                <tr>
                    <td><code>c!!training [args?]</code></td>
                    <td><code>c!!training 5pm</code></td>
                    <td>Sends training message as configured by server manager</td>
                    <td>Users with permissions defined</td>
                    <td><span class="tag cf-sessions is-medium">Sessions</span></td>
                </tr>

                <tr>
                    <td><code>c!!shift [args?]</code></td>
                    <td><code>c!!shift</code></td>
                    <td>Sends shift message as configured by server manager</td>
                    <td>Users with permissions defined</td>
                    <td><span class="tag cf-sessions is-medium">Sessions</span></td>
                </tr>

                <tr>
                    <td><code>c!!interview [args?]</code></td>
                    <td><code>c!!interview 10pm Motivatial</code></td>
                    <td>Sends interview message as configured by server manager</td>
                    <td>Users with permissions defined</td>
                    <td><span class="tag cf-sessions is-medium">Sessions</span></td>
                </tr>

                <tr>
                    <td><code>c!!colour</code></td>
                    <td><code>c!!colour</code></td>
                    <td>Get a random colour</td>
                    <td>When enabled in 'Utilities' on Dashboard.</td>
                    <td><span class="tag is-light is-medium">Utilities</span></td>
                </tr>

                <tr>
                    <td><code>c!!emojify [message]</code></td>
                    <td><code>c!!emojify cool</code></td>
                    <td>Turn your message into emojis</td>
                    <td>When enabled in 'Utilities' on Dashboard.</td>
                    <td><span class="tag is-light is-medium">Utilities</span></td>
                </tr>

                <tr>
                    <td><code>c!!math [calculation]</code></td>
                    <td><code>c!!math 4 + 4</code></td>
                    <td>Complete a maths equation.</td>
                    <td>When enabled in 'Utilities' on Dashboard.</td>
                    <td><span class="tag is-light is-medium">Utilities</span></td>
                </tr>

                <tr>
                    <td><code>c!!spotify [user?]</code></td>
                    <td><code>c!!spotify</code></td>
                    <td>Get Spotify now playing.</td>
                    <td>When enabled in 'Utilities' on Dashboard.</td>
                    <td><span class="tag is-light is-medium">Utilities</span></td>
                </tr>

                <tr>
                    <td><code>c!!suggest [suggestion]</code></td>
                    <td><code>c!!suggestion Add more cats</code></td>
                    <td>Suggest a feature in the suggestions channel</td>
                    <td>When enabled in 'Utilities' on Dashboard.</td>
                    <td><span class="tag is-light is-medium">Utilities</span></td>
                </tr>

                <tr>
                    <td><code>c!!time [timezone]</code></td>
                    <td><code>c!!time EST</code></td>
                    <td>Get the time in the given timezone</td>
                    <td>When enabled in 'Utilities' on Dashboard.</td>
                    <td><span class="tag is-light is-medium">Utilities</span></td>
                </tr>

                <tr>
                    <td><code>c!!timer [time]</code></td>
                    <td><code>c!!timer 5 minutes</code></td>
                    <td>Set a timer for a given length of time</td>
                    <td>When enabled in 'Utilities' on Dashboard.</td>
                    <td><span class="tag is-light is-medium">Utilities</span></td>
                </tr>

                <tr>
                    <td><code>c!!weather [City]</code></td>
                    <td><code>c!!weather New York</code></td>
                    <td>Get the weather of a given place</td>
                    <td>When enabled in 'Utilities' on Dashboard.</td>
                    <td><span class="tag is-light is-medium">Utilities</span></td>
                </tr>

                <tr>
                    <td><code>c!!afk</code></td>
                    <td><code>c!!afk</code></td>
                    <td>Go AFK</td>
                    <td>Public</td>
                    <td><span class="tag is-light is-medium">Utilities</span></td>
                </tr>

                <tr>
                    <td><code>c!!ping</code></td>
                    <td><code>c!!ping api</code></td>
                    <td>Get the response time for the bot</td>
                    <td>Public</td>
                    <td><span class="tag is-light is-medium">Utilities</span></td>
                </tr>

                <tr>
                    <td><code>c!!kick [user] [reason?]</code></td>
                    <td><code>c!!kick @Aaron Breaking rules</code></td>
                    <td>Kick user from server</td>
                    <td>Users with permission defined</td>
                    <td><span class="tag cf-moderation is-medium">Moderation</span></td>
                </tr>

                <tr>
                    <td><code>c!!ban [user] [reason?]</code></td>
                    <td><code>c!!ban @Aaron Breaking rules</code></td>
                    <td>Ban user from server</td>
                    <td>Users with permission defined</td>
                    <td><span class="tag cf-moderation is-medium">Moderation</span></td>
                </tr>

                <tr>
                    <td><code>c!!mute [user] [time] [reason?]</code></td>
                    <td><code>c!!mute @Aaron 1d Breaking rules</code></td>
                    <td>Mute user</td>
                    <td>Users with permission defined</td>
                    <td><span class="tag cf-moderation is-medium">Moderation</span></td>
                </tr>

                <tr>
                    <td><code>c!!warn [user] [reason?]</code></td>
                    <td><code>c!!mute @Aaron Breaking rules</code></td>
                    <td>Warn user</td>
                    <td>Users with permission defined</td>
                    <td><span class="tag cf-moderation is-medium">Moderation</span></td>
                </tr>

                <tr>
                    <td><code>c!!softban [user] [reason?]</code></td>
                    <td><code>c!!softban @Aaron Breaking rules</code></td>
                    <td>Bans and then unbans user immediately. Good for spamming.</td>
                    <td>Users with permission defined</td>
                    <td><span class="tag cf-moderation is-medium">Moderation</span></td>
                </tr>

                <tr>
                    <td><code>c!!prune [amount]</code></td>
                    <td><code>c!!prune 40</code></td>
                    <td>Deletes defined amount of messages. Maximum is 99. You cannot delete messages older than 2 weeks.</td>
                    <td>Users with permission defined</td>
                    <td><span class="tag cf-moderation is-medium">Moderation</span></td>
                </tr>

                <tr>
                    <td><code>c!!cases [user]</code></td>
                    <td><code>c!!cases @Aaron</code></td>
                    <td>Shows all of the moderation history of a Users</td>
                    <td>Users with permission defined</td>
                    <td><span class="tag cf-moderation is-medium">Moderation</span></td>
                </tr>

                <tr>
                    <td><code>c!!resolve [caseId]</code></td>
                    <td><code>c!!resolve UFUUEOvhg848dhYuA</code></td>
                    <td>Deletes a moderation case</td>
                    <td>Users with permission defined</td>
                    <td><span class="tag cf-moderation is-medium">Moderation</span></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="spacer"></div>
</div>
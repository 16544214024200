import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit {
  user: firebase.User;

  constructor(private service: AuthService) { }

  ngOnInit(): void {
    this.service.getLoggedInUser().subscribe(user => {
      this.user = user;
    })
  }

}

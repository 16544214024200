import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-bot-sale',
  templateUrl: './bot-sale.component.html',
  styleUrls: ['./bot-sale.component.css']
})
export class BotSaleComponent implements OnInit {
  user: firebase.User;

  constructor(private service: AuthService, private titleService: Title) { }

  ngOnInit(): void {
    this.service.getLoggedInUser().subscribe(user => {
      this.user = user;
    })

    this.titleService.setTitle(`Bot | Campfire HQ`)
  }

}

<a class="navbar-link">
    <span class="avatar-clip"><img src="{{ user?.photoURL }}" class="is-rounded"></span>
    <span>Hello, {{ user?.displayName }}</span>
</a>
<div class="navbar-dropdown">
    <!--<a class="navbar-item" routerLink="/dashboard">
        <div class="is-flex">
            <div class="icon-box is-grey">
                <span class="icon">
                    <i class="fas fa-columns"></i>
                </span>
            </div>
            <span class="icon-grid-info">
                <strong><span>Dashboard</span></strong>
            </span>
        </div>
    </a>-->

    <a class="navbar-item" routerLink="/app/bot/setup">
        <div class="is-flex">
            <div class="icon-box is-grey">
                <span class="icon">
                    <i class="fas fa-robot"></i>
                </span>
            </div>
            <span class="icon-grid-info">
                <strong><span>Bot</span></strong>
            </span>
        </div>
    </a>

    <a class="navbar-item" routerLink="/app/bot">
        <div class="is-flex">
            <div class="icon-box is-grey">
                <span class="icon">
                    <i class="fas fa-server"></i>
                </span>
            </div>
            <span class="icon-grid-info">
                <strong><span>Servers</span></strong>
            </span>
        </div>
    </a>

    <a class="navbar-item" routerLink="/sapling" *ngIf="staff">
        <div class="is-flex">
            <div class="icon-box is-grey">
                <span class="icon">
                    <i class="fas fa-leaf"></i>
                </span>
            </div>
            <span class="icon-grid-info">
                <strong><span>Sapling</span></strong>
            </span>
        </div>
    </a>

    <a class="navbar-item" routerLink="/pastebook/app">
        <div class="is-flex">
            <div class="icon-box is-grey">
                <span class="icon">
                    <i class="fas fa-paste"></i>
                </span>
            </div>
            <span class="icon-grid-info">
                <strong><span>Pastebook</span></strong>
            </span>
        </div>
    </a>

    <a class="navbar-item" (click)="getBilling()">
        <div class="is-flex">
            <div class="icon-box is-grey">
                <span class="icon">
                    <i class="fas fa-money-bill-wave"></i>
                </span>
            </div>
            <span class="icon-grid-info">
                <strong><span>Billing</span></strong>
            </span>
        </div>
    </a>

    <hr class="navbar-divider">

    <a routerLink="/account" class="navbar-item">Settings</a>
    <a (click)="logout()" class="navbar-item has-text-danger">Log out</a>
</div>
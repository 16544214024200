<nav class="navbar is-light is-spaced">
    <div class="container">
        <div class="navbar-brand">
            <a routerLink="/" class="navbar-item">
                <img src="../../assets/Campfire-Pride.png" alt="Campfire"
                    width="115px">
            </a>

            <a aria-expanded="false" aria-label="menu" class="navbar-burger burger" data-target="campfireMenu"
                role="button" (click)="expand()">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>

        <div class="navbar-menu" id="campfireMenu">
            <div class="navbar-start">
                <a class="navbar-item" routerLink="/why-campfire">Why Campfire?</a>
                <a class="navbar-item" routerLink="/pricing">Pricing</a>

                <div class="navbar-item has-dropdown is-hoverable">
                    <a class="navbar-link">Products</a>

                    <div class="navbar-dropdown">
                        <a class="navbar-item" routerLink="/products/bot">
                            <div class="is-flex">
                                <div class="icon-box">
                                    <span class="icon">
                                        <i class="fas fa-robot"></i>
                                    </span>
                                </div>
                                <span class="icon-grid-info">
                                    <strong><span>Bot</span></strong>
                                    Campfire's flagship bot product
                                </span>
                            </div>
                        </a>

                        <!--<a class="navbar-item" href="https://booster.campfirebot.xyz">
                            <div class="is-flex">
                                <div class="icon-box">
                                    <span class="icon">
                                        <i class="fas fa-gem"></i>
                                    </span>
                                </div>
                                <span class="icon-grid-info">
                                    <strong><span>Booster</span></strong>
                                    Increase your guild boosters
                                </span>
                            </div>
                        </a>-->

                        <!-- <a class="navbar-item" href="https://docs.campfirehq.net/en/ranking">
                            <div class="is-flex">
                                <div class="icon-box">
                                    <span class="icon">
                                        <i class="fas fa-user-cog"></i>
                                    </span>
                                </div>
                                <span class="icon-grid-info">
                                    <strong><span>Ranking</span></strong>
                                    Rank group members programmatically
                                </span>
                            </div>
                        </a> -->

                        <!--<a class="navbar-item" routerLink="/members">
                            <div class="is-flex">
                                <div class="icon-box">
                                    <span class="icon">
                                        <i class="fas fa-user-plus"></i>
                                    </span>
                                </div>
                                <span class="icon-grid-info">
                                    <strong><span>Member Counting</span></strong>
                                    Free member counting webhook
                                </span>
                            </div>
                        </a>-->

                        <a class="navbar-item" routerLink="/pastebook">
                            <div class="is-flex">
                                <div class="icon-box">
                                    <span class="icon">
                                        <i class="fas fa-paste"></i>
                                    </span>
                                </div>
                                <span class="icon-grid-info">
                                    <strong><span>Pastebook</span></strong>
                                    Store things you frequently copy and paste
                                </span>
                            </div>
                        </a>

                        <!--<a class="navbar-item" routerLink="/shouts">
                            <div class="is-flex">
                                <div class="icon-box">
                                    <span class="icon">
                                        <i class="fa fa-bullhorn"></i>
                                    </span>
                                </div>
                                <span class="icon-grid-info">
                                    <strong><span>Shout Proxy</span></strong>
                                    Get group shouts in your Discord
                                </span>
                            </div>
                        </a>-->

                        <!--<hr class="navbar-divider">

                        <a routerLink="/shouts" class="navbar-item">
                            <span class="icon"><i class="fa fa-bullhorn"></i></span> <span>Group Shout Proxy</span>
                        </a>

                        <a routerLink="/products" class="navbar-item">
                            View All Products
                        </a>-->
                    </div>
                </div>

                <!--<a class="navbar-item">Pricing</a>-->

                <div class="navbar-item has-dropdown is-hoverable">
                    <a class="navbar-link">Support</a>

                    <div class="navbar-dropdown">

                        <!-- <a class="navbar-item" href="https://support.campfirehq.net" target="_blank">
                            <div class="is-flex">
                                <div class="icon-box is-grey">
                                    <span class="icon">
                                        <i class="fas fa-life-ring"></i>
                                    </span>
                                </div>
                                <span class="icon-grid-info">
                                    <strong><span>Support</span></strong>
                                    Visit our help desk
                                </span>
                            </div>
                        </a> -->

                        <a class="navbar-item" href="https://status.campfirehq.net" target="_blank">
                            <div class="is-flex">
                                <div class="icon-box is-grey">
                                    <span class="icon">
                                        <i class="fas fa-heartbeat"></i>
                                    </span>
                                </div>
                                <span class="icon-grid-info">
                                    <strong><span>Status</span></strong>
                                    View our system status
                                </span>
                            </div>
                        </a>

                        <!-- <a class="navbar-item" href="https://forum.campfirehq.net" target="_blank">
                            <div class="is-flex">
                                <div class="icon-box is-grey">
                                    <span class="icon">
                                        <i class="fab fa-discourse"></i>
                                    </span>
                                </div>
                                <span class="icon-grid-info">
                                    <strong><span>Forum</span></strong>
                                    Our community-driven forum
                                </span>
                            </div>
                        </a> -->

                        <a class="navbar-item" href="https://discord.gg/tHrSsPB" target="_blank">
                            <div class="is-flex">
                                <div class="icon-box is-grey">
                                    <span class="icon">
                                        <i class="fab fa-discord"></i>
                                    </span>
                                </div>
                                <span class="icon-grid-info">
                                    <strong><span>Discord</span></strong>
                                    Join our Discord server
                                </span>
                            </div>
                        </a>

                    </div>
                </div>
            </div>

            <div class="navbar-end">
                <div class="navbar-item" *ngIf="user">
                    <p class="control">
                        <a routerLink="/app/bot/setup/create" class="button is-primary">Create</a>
                    </p>
                </div>

                <a routerLink="/app/bot/setup/create" *ngIf="user" class="navbar-item has-text-primary">Purchase</a>

                <app-logged-in *ngIf="user" class="navbar-item has-dropdown is-hoverable"></app-logged-in>
                <a *ngIf="!user" class="navbar-item" (click)="login()">Log in</a>
            </div>
        </div>
    </div>
</nav>
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

declare var paypal: any;
declare var Stripe: any;

@Component({
  selector: 'app-ch-bot',
  templateUrl: './ch-bot.component.html',
  styleUrls: ['./ch-bot.component.css']
})
export class ChBotComponent implements OnInit {

  plan = 0;
  paymentMethod = 'stripe';

  stripe: any;
  elements: any;
  card: any;

  cardError: string;

  loadingPayment: boolean = true;

  couponApplied: string;
  couponHelper: string;
  couponError: string;
  couponReduction: number = 0;
  couponChecking: boolean;

  emailFormCompleted: boolean;
  loadingStripeAccount: boolean;

  stripeCustomerId: string;
  customerEmail: string;

  customerHasCards: boolean;
  customerCards = [];

  botId: string;

  loadingPaymentMethod: string;
  preflight: boolean;

  stripeCheckoutSessionId: string;

  @ViewChild('cardElement') cardElement: ElementRef;

  constructor(private titleService: Title, private db: AngularFirestore, private renderer: Renderer2, private func: AngularFireFunctions, private service: AuthService, private analytics: AngularFireAnalytics, private route: ActivatedRoute, private http: HttpClient) {
    route.params.pipe(first()).subscribe(params => {
      this.botId = params.id;
    })
  }

  ngOnInit(): void {

  }

  get3Days() {
    const fromNow = new Date(new Date().getTime() + 2.592e+8);
    return fromNow;
  }

  changePlan(plan) {
    this.plan = plan;
    setTimeout(() => {
      const el = document.getElementById('planBillingContainer');
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
      }
    }, 500);

    this.stripe = Stripe(environment.stripe);

    switch (this.plan) {
      case 1:
        this.service.returnUserToken().then(token => {
          this.http.post<any>(`https://api.campfirehq.net/bots/checkout`, {
            lineItems: [{
              price: 'price_1GrjMKGTPmj332XYEgeF9THa',
              quantity: 1
            }], botId: this.botId
          }, { headers: {
            authorization: token
          }}).pipe(first()).subscribe(callback => {
            if (callback.success) {
              this.stripeCheckoutSessionId = callback.id;
              this.loadingPayment = false;
            } else {
              Swal.fire('Something went wrong', 'Try reloading the page. If problem persists join our Discord for support', 'error');
            }
          }, err => {
            Swal.fire('Something went wrong', 'Try reloading the page. If problem persists join our Discord for support', 'error');
          })
        })
        break;
      case 2:
        this.service.returnUserToken().then(token => {
          this.http.post<any>(`https://api.campfirehq.net/bots/checkout`, {
            lineItems: [{
              price: 'price_1GrjMKGTPmj332XYLO8SUA2R',
              quantity: 1
            }], botId: this.botId
          }, { headers: {
            authorization: token
          }}).pipe(first()).subscribe(callback => {
            if (callback.success) {
              this.stripeCheckoutSessionId = callback.id;
              this.loadingPayment = false;
            } else {
              Swal.fire('Something went wrong', 'Try reloading the page. If problem persists join our Discord for support', 'error');
            }
          }, err => {
            Swal.fire('Something went wrong', 'Try reloading the page. If problem persists join our Discord for support', 'error');
          })
        })
        break;
      case 3:
        this.service.returnUserToken().then(token => {
          this.http.post<any>(`https://api.campfirehq.net/bots/checkout`, {
            lineItems: [{
              price: 'price_1GrjMKGTPmj332XYYAvHsSRX',
              quantity: 1
            }], botId: this.botId
          }, { headers: {
            authorization: token
          }}).pipe(first()).subscribe(callback => {
            if (callback.success) {
              this.stripeCheckoutSessionId = callback.id;
              this.loadingPayment = false;
            } else {
              Swal.fire('Something went wrong', 'Try reloading the page. If problem persists join our Discord for support', 'error');
            }
          }, err => {
            Swal.fire('Something went wrong', 'Try reloading the page. If problem persists join our Discord for support', 'error');
          })
        })
        break;
    }
  }

  changePaymentMethod(type) {
    if (type === 'paypal') {

      document.getElementById('paypal-drop-in-plan1').innerHTML = "";
      document.getElementById('paypal-drop-in-plan2').innerHTML = "";
      document.getElementById('paypal-drop-in-plan3').innerHTML = "";

      this.paymentMethod = 'paypal';
      paypal
        .Buttons({
          style: {
            color: 'gold',
            layout: 'horizontal',
            label: 'paypal',
            tagline: false,
            size: 'resposive'
          },
          createSubscription: (data, actions) => {
            return actions.subscription.create({
              plan_id: 'P-6MN773778E652881NL44YFLQ',
              custom_id: this.botId
            });
          },
          onApprove: async (data, actions) => {
            console.log(data);
            this.service.returnUserToken().then(token => {
              this.http.post<any>(`https://api.campfirehq.net/bots/pptransact`, {
                subId: data.subscriptionID, botId: this.botId
              }, {
                headers: {
                  authorization: token
                }
              }).pipe(first()).subscribe(returned => {
                if (returned.success === true) {
                  window.location.href = "https://campfirehq.net/thank-you"
                } else {
                  Swal.fire('Error', 'An error occured', 'error');
                }
              }, err => {
                Swal.fire('Error', 'An error occured', 'error');
              })
            })
          },
          onError: err => {
            console.log(err);
            Swal.fire('Error', 'An error occured', 'error');
          }
        })
        .render(document.getElementById('paypal-drop-in-plan1'));

      paypal
        .Buttons({
          style: {
            color: 'gold',
            layout: 'horizontal',
            label: 'paypal',
            tagline: false,
            size: 'resposive'
          },
          createSubscription: (data, actions) => {
            return actions.subscription.create({
              plan_id: 'P-23D5334029863250LL44YFZY',
              custom_id: this.botId
            });
          },
          onApprove: async (data, actions) => {
            console.log(data);
            this.service.returnUserToken().then(token => {
              this.http.post<any>(`https://api.campfirehq.net/bots/pptransact`, {
                subId: data.subscriptionID, botId: this.botId
              }, {
                headers: {
                  authorization: token
                }
              }).pipe(first()).subscribe(returned => {
                if (returned.success === true) {
                  window.location.href = "https://campfirehq.net/thank-you"
                } else {
                  Swal.fire('Error', 'An error occured', 'error');
                }
              }, err => {
                Swal.fire('Error', 'An error occured', 'error');
              })
            })
          },
          onError: err => {
            console.log(err);
            Swal.fire('Error', 'An error occured', 'error');
          }
        })
        .render(document.getElementById('paypal-drop-in-plan2'));

      paypal
        .Buttons({
          style: {
            color: 'gold',
            layout: 'horizontal',
            label: 'paypal',
            tagline: false,
            size: 'resposive'
          },
          createSubscription: (data, actions) => {
            return actions.subscription.create({
              plan_id: 'P-3HG58444UD630904JL44YGHI',
              custom_id: this.botId
            });
          },
          onApprove: async (data, actions) => {
            console.log(data);
            this.service.returnUserToken().then(token => {
              this.http.post<any>(`https://api.campfirehq.net/bots/pptransact`, {
                subId: data.subscriptionID, botId: this.botId
              }, {
                headers: {
                  authorization: token
                }
              }).pipe(first()).subscribe(returned => {
                if (returned.success === true) {
                  window.location.href = "https://campfirehq.net/thank-you"
                } else {
                  Swal.fire('Error', 'An error occured', 'error');
                }
              }, err => {
                Swal.fire('Error', 'An error occured', 'error');
              })
            })
          },
          onError: err => {
            console.log(err);
            Swal.fire('Error', 'An error occured', 'error');
          }
        })
        .render(document.getElementById('paypal-drop-in-plan3'));
    } else if (type === 'stripe') {
      this.paymentMethod = 'stripe';
    }
  }

  initCheckoutSession() {
    this.stripe.redirectToCheckout({
      sessionId: this.stripeCheckoutSessionId
    })
  }

}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bot-help',
  templateUrl: './bot-help.component.html',
  styleUrls: ['./bot-help.component.css']
})
export class BotHelpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAqzEfWyCnsZDGXVOJwDRivc15Rk-EVKSg",
    authDomain: "campfire-hq.firebaseapp.com",
    databaseURL: "https://campfire-hq.firebaseio.com",
    projectId: "campfire-hq",
    storageBucket: "campfire-hq.appspot.com",
    messagingSenderId: "145754860723",
    appId: "1:145754860723:web:f4d467a38524b8cb2054c6",
    measurementId: "G-YT6D70BR1M"
  },
  stripe: 'pk_live_m72qbLRg6uYvEBGNsZpjfeWK009RS15GFI'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

<div class="hero is-medium is-light">
    <div class="hero-body">
        <div class="container has-text-centered">
            <h1 class="title is-size-1" *ngIf="!user">Log in</h1>
            <h2 class="subtitle is-size-4" *ngIf="!user">Log in with email and password</h2>

            <h1 class="title is-size-1" *ngIf="user">Configure</h1>
            
            <div class="columns">
                <div class="column is-3 is-hidden-touch"></div>
                <div class="column">
                    <div class="box has-text-left" *ngIf="!user">
                        <form (ngSubmit)="signIn(signinform)" #signinform="ngForm">
                            <div class="field">
                                <label class="label">Email</label>
                                <input type="email" ngModel required name="email" class="input">
                            </div>

                            <div class="field">
                                <label class="label">Password</label>
                                <input type="password" ngModel required name="password" class="input">
                            </div>

                            <div class="field">
                                <button class="button is-fullwidth is-primary" type="submit">Log in</button>
                            </div>
                        </form>

                        <div class="spacer"></div>

                        <div class="notification is-danger" *ngIf="errorMessageFromFirebase">
                            {{ errorMessageFromFirebase }}
                        </div>
                    </div>

                    <div class="box has-text-left" *ngIf="user">
                        <form (ngSubmit)="updateUser(updateuserform)" #updateuserform="ngForm">
                            <div class="field">
                                <label class="label">Display Name</label>
                                <input type="text" ngModel required name="name" class="input">
                            </div>

                            <div class="field">
                                <button class="button is-fullwidth is-primary" type="submit">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="column is-3 is-hidden-touch"></div>
            </div>
        </div>
    </div>
</div>
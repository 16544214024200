<div class="hero is-light">
    <div class="hero-body">
        <div class="container has-text-centered">
            <h1 class="title is-size-1">Pricing</h1>
            <p class="subtitle">Campfire has fair, consistent and predictable pricing.</p>
        </div>
    </div>
</div>

<div class="spacer"></div>

<div class="hero is-medium">
    <div class="hero-body">
        <div class="container">
            <div class="columns has-text-centered">
                <div class="column">
                    <div class="box">
                        <p>1 Month</p>
                        <hr>
                        <h2 class="title is-size-2">$4.99</h2>
                        <h4 class="subtitle is-size-4">per month</h4>

                        <a class="button is-primary is-fullwidth" routerLink="/app/bot/setup/create">Get 1 Month
                            Plan</a>
                        <hr>
                        <p>7 day money back guarantee</p>
                    </div>
                </div>

                <div class="column rel-pos">
                    <p class="plan-header">Save 35%</p>
                    <div class="box is-highlighted">
                        <br>
                        <p>12 Months + 3 Free Months</p>
                        <hr>
                        <h2 class="title is-size-2">$4.00</h2>
                        <h4 class="subtitle is-size-4">per month</h4>

                        <a class="button is-primary is-fullwidth" routerLink="/app/bot/setup/create">Get 15 Month
                            Plan</a>
                        <hr>
                        <p><del class="has-text-primary">$74.85</del>$48.00 billed every 15 months</p>
                        <p>7 day money back guarantee</p>
                    </div>
                </div>

                <div class="column">
                    <div class="box">
                        <p>6 Months</p>
                        <hr>
                        <h2 class="title is-size-2">$4.56</h2>
                        <h4 class="subtitle is-size-4">per month</h4>

                        <p style="margin-bottom: 10px; color: rgb(255, 71, 71);"><b>Limited time: Pay by card and get 14
                                days
                                free.</b></p>

                        <a class="button is-primary is-fullwidth" routerLink="/app/bot/setup/create">Get 6 Month
                            Plan</a>
                        <hr>
                        <p><del class="has-text-primary">$29.94</del>$27.36 billed every 6 months</p>
                        <p>7 day money back guarantee</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="hero is-primary">
    <div class="hero-body">
        <div class="container">
            <h4 class="title is-size-2 has-text-centered">How do we stack up?</h4>

            <table class="table is-fullwidth">
                <thead>
                    <tr>
                        <th style="vertical-align: middle;">Feature</th>
                        <td>
                            <img src="../../assets/marketing/Campfire-Brilliant-Purple.png" width="60px">
                        </td>

                        <td>
                            <img src="../../assets/marketing/comp/bloxlink.png" width="60px"
                                style="border-radius: 100%;">
                        </td>

                        <td>
                            <img src="../../assets/marketing/comp/koal.png" width="60px"
                                style="border-radius: 100%;">
                        </td>

                        <td>
                            <img src="../../assets/marketing/comp/botghost.png" width="60px"
                                style="border-radius: 100%;">
                        </td>
                    </tr>
                </thead>

                <tbody>
                    <tr class="has-text-centered">
                        <th>Pricing</th>

                        <td><span class="has-text-primary tooltip" data-tooltip="Cheapest">$4.99/month</span></td>
                        <td>$6.00/month</td>
                        <td>$5.00/month <span class="icon tooltip" data-tooltip="Koal will price match"><i class="fa fa-question-circle"></i></span></td>
                        <td>$10.00/month</td>
                    </tr>

                    <tr>
                        <th>Region Selection</th>

                        <td>9 <span class="icon tooltip" data-tooltip="New York, San Fransico, Frankfurt, London, Johannesburg, Nevada, Chicago, Paris, Helsinki"><i class="fa fa-question-circle"></i></span></td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                    </tr>

                    <tr>
                        <th>Support Team</th>

                        <td><span class="icon is-medium has-text-success"><i class="fas fa-lg fa-check"></i></span></td>
                        <td><span class="icon is-medium has-text-success"><i class="fas fa-lg fa-check"></i></span></td>
                        <td><span class="icon is-medium has-text-success"><i class="fas fa-lg fa-check"></i></span></td>
                        <td><span class="icon is-medium has-text-success"><i class="fas fa-lg fa-check"></i></span></td>
                    </tr>

                    <tr>
                        <th>Roblox Verification</th>

                        <td><span class="icon is-medium has-text-success"><i class="fas fa-lg fa-check"></i></span></td>
                        <td><span class="icon is-medium has-text-success"><i class="fas fa-lg fa-check"></i></span></td>
                        <td><span class="icon is-medium has-text-success"><i class="fas fa-lg fa-check"></i></span></td>
                        <td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>
                    </tr>

                    <tr>
                        <th>Suggestion System</th>

                        <td><span class="icon is-medium has-text-success"><i class="fas fa-lg fa-check"></i></span></td>
                        <td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>
                        <td><span class="icon is-medium has-text-success"><i class="fas fa-lg fa-check"></i></span></td>
                        <td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>
                    </tr>

                    <tr>
                        <th>Ticket/ModMail</th>

                        <td>
                            <span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span>
                            <span class="icon is-medium tooltip" data-tooltip="Coming soon"><i class="fa fa-question-circle"></i></span>
                        </td>
                        <td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>
                        <td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>
                        <td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>
                    </tr>

                    <tr>
                        <th>Service Level Agreement</th>

                        <td><span class="icon is-medium has-text-success"><i class="fas fa-lg fa-check"></i></span></td>
                        <td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>
                        <td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>
                        <td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>
                    </tr>

                    <tr>
                        <th>Mutli-cloud <span class="icon tooltip" data-tooltip="Provider uses several platforms to ensure redundancy"><i class="fa fa-question-circle"></i></span></th>

                        <td><span class="icon is-medium has-text-success"><i class="fas fa-lg fa-check"></i></span></td>
                        <td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>
                        <td><span class="icon is-medium tooltip" data-tooltip="No data available"><i class="fa fa-lg fa-question-circle"></i></span></td>
                        <td><span class="icon is-medium tooltip" data-tooltip="No data available"><i class="fa fa-lg fa-question-circle"></i></span></td>
                    </tr>

                    <tr>
                        <th>Sessions</th>

                        <td><span class="icon is-medium has-text-success"><i class="fas fa-lg fa-check"></i></span></td>
                        <td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>
                        <!--<td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>-->
                        <td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>
                        <td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>
                    </tr>

                    <tr>
                        <th>Custom Commands</th>

                        <td><span class="icon is-medium has-text-success"><i class="fas fa-lg fa-check"></i></span></td>
                        <td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>
                       <!-- <td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>-->
                        <td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>
                        <td><span class="icon is-medium has-text-success"><i class="fas fa-lg fa-check"></i></span></td>
                    </tr>

                    <tr>
                        <th>Hyra Integration</th>

                        <td><span class="icon is-medium tooltip" data-tooltip="Coming soon"><i class="fa fa-lg fa-question-circle"></i></span></td>
                        <td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>
                       <!-- <td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>-->
                        <td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>
                        <td><span class="icon is-medium has-text-danger"><i class="fas fa-lg fa-times"></i></span></td>
                    </tr>

                    <tr class="has-text-centered">
                        <th>Support Times</th>

                        <td>8 hours or less</td>
                        <td>
                            <span class="icon tooltip" data-tooltip="Community Based - This means there is no guarantee"><i class="fa fa-question-circle"></i></span>
                        </td>
                        <td><span class="icon tooltip" data-tooltip="No data available"><i class="fa fa-question-circle"></i></span></td>
                        <td><span class="icon tooltip" data-tooltip="No data available"><i class="fa fa-question-circle"></i></span></td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr>
                        <th></th>
                        <th>
                            <a class="button is-primary" routerLink="/app/bot/setup/create">Get started</a>
                        </th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>

            <p class="has-text-centered">Accurate as of 1st February 2021. Compares lowest priced equivalent plan.</p>
        </div>
    </div>
</div>

<div class="hero is-light">
    <div class="hero-body">
        <div class="container">
            <h4 class="title is-size-3">What are you waiting for? <span class="has-text-primary">Sign up today.</span></h4>

            <a class="button is-primary" routerLink="/app/bot/setup/create">Purchase</a>
        </div>
    </div>
</div>
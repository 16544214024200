<div class="cookie-popup" *ngIf="cookieConsent">
    <div class="cp-info">
        <p><span class="icon has-text-warning"><i class="fas fa-cookie-bite"></i></span> Like most websites, this
            website uses cookies.<br>For more information you can read our <a routerLink="/privacy">Privacy Policy</a>
        </p>
    </div>

    <div class="cp-agree">
        <a class="button is-info" (click)="consent()">Dismiss</a>
    </div>
</div>
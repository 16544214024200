import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router, NavigationStart } from '@angular/router';

declare var $crisp: any;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  constructor(private service: AuthService, private router: Router) { }

  user: firebase.User;

  ngOnInit() {
    if(window) {
      window.onmessage = e => {
        if (e.origin === 'https://campfirehq.net' || e.origin === 'https://api.campfirehq.net') {
          if(e.data.auth) {
            this.service.pushToken(e.data);
          }
        }
      }
    }

    this.service.getLoggedInUser().subscribe(user => {
      this.user = user;

      if(user) {
        $crisp.push(["set", "user:nickname", user.displayName])
        $crisp.push(["set", "user:avatar", user.photoURL])
      }
    })

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        try {
          document.getElementById('campfireMenu').classList.remove('is-active');
          document.querySelector('.navbar-burger').classList.remove('is-active');
          window['dataLayer'].push({ 'event': 'optimize.activate' });
        } catch {
        }
      }
    })
  }
  
  login() {
    this.service.login();
  }

  expand() {
    document.getElementById('campfireMenu').classList.toggle('is-active');
    document.querySelector('.navbar-burger').classList.toggle('is-active');
  }

}

<div class="hero is-light">
    <div class="hero-body">
        <div class="container has-text-centered">
            <img src="../../assets/thank-you.svg" width="20%" class="animated fadeInUp">
            <h1 class="title is-size-1 animated fadeInUp delay-1s"> <i class="twa twa-sparkling_heart animated pulse infinite"></i> Thank you, {{ user?.displayName }}!</h1>
            <p class="subtitle is-size-3 animated fadeInUp delay-1s"><i class="twa twa-fireworks"></i> Welcome to the family! It's lovely to have you on-board.</p>
            <p class="animated fadeInUp delay-2s">Your monthly subscription is what makes this possible. From all of us at Campfire, a <b>huge</b> <span class="has-text-primary"> thank you!</span></p>
            <p class="animated fadeInUp delay-3s"><small>We've mailed your invoice to your inbox. You're free to leave at any point by clicking the 'Billing' button.</small></p>
        </div>
    </div>
</div>
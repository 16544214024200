import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Campfire-HQ';

  ngOnInit() {
    if(window.location.host == 'campfire-hq.web.app') {
      window.location.href = 'https://campfirebot.xyz'
    }

    if(window.localStorage.getItem('acc') == 'true') {
      document.body.classList.add('ds');
    }
  }
}
